html {
  scroll-behavior: smooth;
}

:focus,
button:focus,
.btn:focus,
.btn-sm:focus,
.form-input:focus,
.form-textarea:focus,
.form-multiselect:focus,
.form-select:focus,
.form-checkbox:focus,
.form-radio:focus {
  outline: none;
}

.likeButton {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.ant-layout-sider-children {
  .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }
}

.site-layout .site-layout-background {
  background: #fff;
}
.ant-list-item-meta {
  align-items: center;
}
