
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=fallback');

@tailwind base;
@tailwind components;
@import 'additional-styles/utility-patterns.scss';
@import 'additional-styles/theme.scss';
@import 'react-big-calendar/lib/css/react-big-calendar.css';
@import '~antd/dist/antd.css';


@tailwind utilities;

$aos-distance: 10px;
@import '../../node_modules/aos/src/sass/aos.scss';

